<script>
import { mapActions, mapGetters } from 'vuex';
import UserModal from '@/views/dashboard/components/modals/UserModal';
import helper from '@/plugins/helper';
import RESERVED_STATUSES from '@/constants/reserved_statuses';

export default {
    name: 'Reserved',
    components: { UserModal },
    data: () => ({
        RESERVED_STATUSES: RESERVED_STATUSES,
        menu: false,
        loading: false,
        loadingExcel: false,
        loadingApprove: false,
        loadingDecline: false,

        approve_dialog: false,
        decline_dialog: false,
        transactionId: '',
        filter: {
            date: [],
            payment_id: '',
            amount: '',
            email: '',
            quantity_lots: '',
        },
    }),
    methods: {
        ...mapActions('reserved', [
            'fetchReserved',
            'postApproveReserved',
            'postDeclineReserved',
            'getAllReservedExcel',
        ]),
        ...mapActions('user_modal', ['fetchUserInfo']),

        async downloadReservedExcel() {
            this.loadingExcel = true;

            await this.getAllReservedExcel().then(res => {
                const url = window.URL.createObjectURL(new Blob([res]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'all-reserved.xlsx');
                document.body.appendChild(link);
                link.click();
            });
            this.loadingExcel = false;
        },

        async approveReserved() {
            this.loadingApprove = true;

            await this.postApproveReserved(
                'transaction_id' + '=' + this.transactionId,
            ).then(data => {
                if (data) {
                    this.$alertify.success('Reserved approved');
                    this.fetchReserved();
                } else {
                    this.$alertify.error('Approve error');
                }
            });

            this.loadingApprove = false;
            this.approve_dialog = false;
        },

        async declineReserved() {
            this.loadingDecline = true;
            
            await this.postDeclineReserved(
                'transaction_id' + '=' + this.transactionId,
            ).then(data => {
                if (data) {
                    this.$alertify.success('Reserved declined');
                    this.fetchReserved();
                } else {
                    this.$alertify.error('Decline error');
                }
            });

            this.loadingDecline = false;
            this.decline_dialog = false;
        },

        setTempItem(item, approve) {
            if (!approve) this.decline_dialog = true;
            else this.approve_dialog = true;
            this.transactionId = item.transaction_id;
        },

        numberWithSpaces(num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        },

        copyText(text) {
            try {
                navigator.clipboard.writeText(text);
                this.$alertify.success('Payment ID copied successfully');
            } catch (error) {
                this.$alertify.error('Payment ID copied error');
            }
        },

        getColorByStatus(status) {
            let color;
            switch (parseInt(status)) {
                case RESERVED_STATUSES.PENDING:
                    color = 'orange';
                    break;
                case RESERVED_STATUSES.DONE:
                    color = 'green';
                    break;
                case RESERVED_STATUSES.DECLINE:
                    color = 'red';
                    break;
                default:
                    color = null;
                    break;
            }
            return color;
        },

        resetDate() {
            this.filter.date = [];
            this.menu = false;
        },

        dataDayYear(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('fr-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
            return formatter.format(new Date(+time * 1000));
        },

        dataTime(value) {
            const time = +value;
            if (!time || typeof time === 'string') return '0';
            const formatter = new Intl.DateTimeFormat('ru', {
                timeStyle: 'medium',
                hour12: false,
            });
            return formatter.format(new Date(+time * 1000));
        },
    },

    computed: {
        ...mapGetters('reserved', {
            reserved: 'getReserved',
        }),
        headers() {
            return [
                {
                    text: 'Date of create',
                    value: 'time',
                    filter: (value, search, item) => {
                        const THREE_HOURS_IN_SECONDS = 3 * 3600;
                        const DAY_IN_SECONDS = 86399;

                        if (this.filter.date.length === 2) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp = helper.toTimestamp(
                                this.filter.date['1'],
                            );

                            if (startDateTimestamp > endDateTimestamp) {
                                [startDateTimestamp, endDateTimestamp] = [
                                    endDateTimestamp,
                                    startDateTimestamp,
                                ];
                            }
                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.time &&
                                endDateTimestamp +
                                    DAY_IN_SECONDS -
                                    THREE_HOURS_IN_SECONDS >=
                                    item.time
                            );
                        }

                        if (this.filter.date.length === 1) {
                            let startDateTimestamp = helper.toTimestamp(
                                this.filter.date['0'],
                            );
                            let endDateTimestamp =
                                startDateTimestamp + DAY_IN_SECONDS;

                            return (
                                startDateTimestamp - THREE_HOURS_IN_SECONDS <=
                                    item.time &&
                                endDateTimestamp - THREE_HOURS_IN_SECONDS >=
                                    item.time
                            );
                        }

                        return true;
                    },
                    align: 'center',
                },
                {
                    text: 'Payment ID',
                    value: 'payment_id',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.payment_id.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Email',
                    value: 'email',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.email.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Amount (€)',
                    value: 'amount',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.amount.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Quantity shares',
                    value: 'quantity_lots',
                    filter: value => {
                        return (value + '')
                            .toLowerCase()
                            .includes(this.filter.quantity_lots.toLowerCase());
                    },
                    align: 'center',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'action',
                    align: 'center',
                    sortable: false,
                },
            ];
        },
    },
    async created() {
        this.loading = true;
        await this.fetchReserved();
        this.loading = false;
    },
};
</script>

<style scoped>
.startup__actions {
    top: -60px;
    right: 5%;
    z-index: 1;
    position: absolute;
}

.btnDownload {
    cursor: pointer;
}
</style>

<template>
    <div>
        <div class="startup__actions">
            <v-btn
                class="btnDownload"
                color="#47a4db"
                :loading="loadingExcel"
                @click="downloadReservedExcel"
            >
                Download Excel
            </v-btn>
        </div>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            dense
            :headers="headers"
            :items="reserved"
            :items-per-page="50"
            :footer-props="{
                'items-per-page-options': [50, 100, 150],
            }"
        >
            <template v-slot:[`item.amount`]="{ item }">
                {{ numberWithSpaces(item.amount) }}
            </template>

            <template v-slot:[`item.quantity_lots`]="{ item }">
                {{ numberWithSpaces(item.quantity_lots) }}
            </template>

            <template v-slot:[`item.email`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <a
                            v-on="on"
                            v-bind="attrs"
                            class="blue--text"
                            @click.prevent="fetchUserInfo(item.user_id)"
                            >{{ item.email }}</a
                        >
                    </template>
                    <span>User id {{ item.user_id }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.time`]="{ item }">
                <span style="margin-right: 10px">
                    {{ dataDayYear(item.time) }}
                </span>
                {{ dataTime(item.time) }}
            </template>

            <template v-slot:[`item.payment_id`]="{ item }">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <span
                            @click="copyText(item.payment_id)"
                            v-on="on"
                            v-bind="attrs"
                            style="cursor: pointer"
                        >
                            {{ item.payment_id }}</span
                        >
                    </template>
                    <span>{{ item.payment_id }}</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <v-tooltip
                    bottom
                    v-if="parseInt(item.status) === RESERVED_STATUSES.PENDING"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Pending
                        </v-chip>
                    </template>
                    <span>Pending status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="parseInt(item.status) === RESERVED_STATUSES.DONE"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Done
                        </v-chip>
                    </template>
                    <span>Done status</span>
                </v-tooltip>
                <v-tooltip
                    bottom
                    v-if="parseInt(item.status) === RESERVED_STATUSES.DECLINE"
                    max-width="400px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-chip
                            style="cursor: pointer"
                            v-on="on"
                            v-bind="attrs"
                            small
                            dark
                            :color="getColorByStatus(item.status)"
                        >
                            Decline
                        </v-chip>
                    </template>
                    <span>Decline status</span>
                </v-tooltip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
                <div style="display: flex; justify-content: center">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="success"
                                v-on="on"
                                v-bind="attrs"
                                text
                                icon
                                @click="setTempItem(item, true)"
                                style="height: 24px"
                            >
                                <v-icon>mdi-check</v-icon>
                            </v-btn>
                        </template>
                        <span>Approve reserved?</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="error"
                                v-on="on"
                                v-bind="attrs"
                                text
                                icon
                                style="margin-left: 5px; height: 24px"
                                @click="setTempItem(item, false)"
                            >
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </template>
                        <span>Decline reserved?</span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:[`body.prepend`]>
                <tr>
                    <td>
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="filter.date"
                                    label="Date range"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="filter.date" no-title range>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="resetDate">
                                    Clear
                                </v-btn>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="menu = false"
                                >
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.payment_id"
                        ></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.email"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.amount"></v-text-field>
                    </td>
                    <td>
                        <v-text-field
                            v-model="filter.quantity_lots"
                        ></v-text-field>
                    </td>
                </tr>
            </template>
        </v-data-table>
        <v-dialog v-model="approve_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Approve reserved?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="approve_dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        :loading="loadingApprove"
                        @click="approveReserved()"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="decline_dialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">
                    Decline reserved?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="red darken-1"
                        text
                        @click="decline_dialog = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="green darken-1"
                        text
                        :loading="loadingDecline"
                        @click="declineReserved()"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <user-modal />
    </div>
</template>
